import React from "react"

import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"

const TestPage: React.FC<GatsbyProps> = ({ location }) => (
  <StandardLayout location={location}></StandardLayout>
)

export default TestPage
